import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import React from 'react';
import FluidImage from '@utility/FluidImage/index';
import ImageTextOverlap from '@components/ImageTextOverlap';
import ScrollInView from '@utility/ScrollInView/index';
import Link from '@utility/Link';
import cn from 'classnames';
import * as styles from './styles.module.scss';

const BlogListing = ({ data: { posts, featuredPost } }) => {
  const recentPosts = useStaticQuery(
    graphql`
      query {
        allSanityBlogPost(
          sort: { order: DESC, fields: date }
          filter: { slug: { current: { ne: null } } }
          limit: 3
        ) {
          edges {
            node {
              date(formatString: "MM/DD/YYYY")
              category {
                title
              }
              author
              title
              description
              _id
              image {
                asset {
                  _id
                }
              }
              slug {
                current
              }
            }
          }
        }
      }
    `,
  );
  const renderFeature = (feature, reverse = false) => (
    <div className="mb-30">
      <ImageTextOverlap
        data={{
          contentBox: {
            heading: feature.title,
            paragraph: feature.description,
            subheading: feature.category.title,
            link: [{ label: 'Read more', ariaLabel: `Read more about ${feature.title}`, page: { slug: feature.slug } }],
          },
          image: feature.image,
          reverse,
        }}
      />
    </div>
  );

  const renderListing = (post) => (
    <div
      className="row py-8 border-t border-solid border-mono-200"
      key={post._id}
    >
      <div className="col-12 md:col-4 mb-2 md:mb-0">
        <p className="type-upper-040 mt-2 mb-1">{post.category.title}</p>
        <p className="type-upper-040 text-mono-500 mb-1/2">{post.date}</p>
        <p className="type-sans-040 text-mono-500">{post.author}</p>
      </div>
      <div className={cn(styles.listingContent, 'col-12 md:col-6 lg:col-4')}>
        <h2 className="type-sans-630 mb-4">
          <Link
            to={`/${post.slug.current}`}
            className={styles.listingLink}
            styled={false}
            aria-label={post.title}
          >
            {post.title}
          </Link>
        </h2>
        <p className={styles.listingDescription}>{post.description}</p>
      </div>
      <div className="relative lg:col-3">
        <div className={styles.listingImageWrapper}>
          <div className={styles.listingImageWrapperInner}>
            <FluidImage src={post.image} maxWidth={568} maxHeight={424} />
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <ScrollInView>
      <div className="wrapper">
        {featuredPost && renderFeature(featuredPost)}
        {posts && posts.length
          ? posts.map((post) => renderListing(post))
          : recentPosts.allSanityBlogPost.edges.map((post) =>
              renderListing(post.node),
            )}
      </div>
    </ScrollInView>
  );
};

BlogListing.propTypes = {
  data: PropTypes.shape({
    featuredPost: PropTypes.object,
    posts: PropTypes.arrayOf(PropTypes.object),
  }),
};

BlogListing.defaultProps = {
  data: {
    featuredPost: {},
    posts: [],
  },
};

export default BlogListing;
